@import "../global.scss";

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5; /* Light gray background */
}

.homescreen {
  width: 100%;
  height: 100vh;
  color: #333;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8; /* Vibrant purple background */
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.data-display {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #6c5ce7; /* Purple heading */
  }

  .data-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .field {
      flex: 1;
      display: flex;
      flex-direction: column;

      .label {
        font-weight: bold;
        font-size: 1rem;
        color: #333; /* Dark label text */
      }

      .value {
        margin-top: 4px;
        font-size: 0.9rem;
        color: #555; /* Slightly darker value text */
      }
    }
  }
}

