.Refillscreen3 {
  width: 100vw;
  height: 100%;
  // color: #3a0360;
  color: black;
  display: flex;
  flex-direction: column;
  .OTPform {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;

    width: 100%;

    .digit-group {
      align-self: center;
      input {
        width: 30px;
        height: 50px;
        background-color: rgb(81, 80, 80);

        color: white;

        border: none;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        font-family: "Raleway", sans-serif;
        font-weight: 200;
        // color: white;
        margin: 0 2px;
      }

      .splitter {
        padding: 0 5px;
        color: black;
        font-size: 24px;
      }
    }
  }
  button {
    background-color: #3a0360;
    // color: #3a0360;
    color: #ffffff;
    font-size: 1rem;
    width: 100%;
    // padding: 2%;
    font-weight: 900;
    margin-top: 3vh;
    margin-bottom: 3vh;
    justify-self: center;
    align-self: center;
    border: 2px solid white;
    border-radius: 6px;
  }
  .error {
    color: red;
    margin-top: -2vh;
    margin-bottom: 2vh;
  }
  .Refill_navbar3 {
    // -webkit-box-shadow: 0px 11px 17px -9px rgba(58, 3, 96, 1);
    // -moz-box-shadow: 0px 11px 17px -9px rgba(58, 3, 96, 1);
    box-shadow: 0px 10px 12px -9px rgba(58, 3, 96, 1);
    display: flex;
    height: 15%;
    width: 100vw;
    background-color: #ffffff;
    // color: white;
    position: fixed;
    // flex-direction: column;
    .navcontent {
      display: flex;
      // height: 10vh;
      width: 100vw;
      // background-color: red;

      position: fixed;
      margin: 3vh;
      h2 {
        // margin-left: 5%;
      }
    }
    .progressbar {
      // background-color: green;

      margin-top: 10%;
      align-self: center;
      align-content: center;
      // align-self: flex-end;
      width: 100vw;
      // background-color: #786b6b;
      display: flex;
      flex-direction: column;
      .progress {
        align-self: center;

        height: 0.95vh;
        // border-radius: 0%;
        width: 50%;
      }
      .progress-bar {
        background-color: #3a0360;
      }
    }
  }
}
