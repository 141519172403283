.logged-out-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.h1x {
  font-size: 3rem;
  margin-bottom: 2rem;
}
p {
  margin: 0 !important;
  padding: 0%;
}
.px {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
