@import "../global.scss";

.OTPscreen,
.bg {
  color: white;
  background-color: #573a87;
  margin: 0; /* Reset default margin */
  margin-bottom: 10px;
}

.OTPcontent {
  font-size: 1.7rem;
  padding-left: 20px;
}

h1 {
  margin-bottom: 1vh;
  font-size: 2rem;
}

p {
  margin-top: 0;
  font-size: 1.3rem;
  opacity: 0.6;
  padding: 0.5rem;
}

.OTPform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;

  .otp-container {
    border: 2px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    max-width: 90vw; /* Responsive container width */
    width: 700px; /* Set a max-width for large screens */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80vh; /* Set height as 60% of the viewport height */
    max-height: 600px;
    margin: 0 20px; /* Add some horizontal space around the container */
  }

  .digit-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    flex-wrap: wrap; /* Allow input fields to wrap on small screens */
  }

  input[type="text"] {
    width: 80px;
    height: 80px;
    text-align: center;
    font-size: 2rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px; /* Add some space between input fields */
  }

  button,
  .OTPloginwithusernamepassword {
    background-color: white;
    color: #3e016a;
    font-size: 1.5rem;
    padding: 2%;
    font-weight: 900;
    border: 2px solid white;
    border-radius: 6px;
    margin: 10px 0; /* Add some space above and below the buttons */
  }

  @media (max-width: 768px) {
    .otp-container {
      height: auto; /* Allow container to grow with content on smaller screens */
      max-height: none; /* Reset max-height for smaller screens */
    }
  }
}
.back-button {
  color: #fff; /* White color for text and icon */
  font-size: 1.8rem;
  display: flex;
  align-items: center; /* Center icon vertically */
  padding-right: 15px;
}
