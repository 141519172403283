// Define colors and other styles here
$primary-color: #007bff;
$secondary-color: #573a87;

// Style for the container div
.pdivxyz {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  // height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
// Style for the heading
.h4x {
  font-size: 24px;
  color: $primary-color;
  margin-bottom: 20px;
}

// Style for the buttons
.buttonx {
  background-color: $primary-color;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &.secondary {
    background-color: $secondary-color;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }
}
