.user-profile-form {
  width: 100%;
  height: 100vh;
  color: #333;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8; /* Vibrant purple background */
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: '#6c5ce7';
  }

  .form-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
      flex: 1; /* Adjust to control the width of the header */
      color: '#6c5ce7';
    }

    .form-group {
      display: flex;
      align-items: center;
      margin-right: 20px; /* Adjust the spacing between groups */
      margin-bottom: 10px;
      flex-direction: row;
      flex-wrap: wrap;
      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input[type="text"],
      input[type="checkbox"] {
        width: 90%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
      }

      input[type="checkbox"] {
        width: auto;
      }
    }
  }

  button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3a0360;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 20px 0; 

    &:hover {
      background-color: #463c92;
    }
  }
}
.data-display {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
